import enums from "enums/index";

export default {
  resource: {
    [enums.Resource.ACCESS_MANAGEMENT]: "Access Management",
  },
  roleCode: {
    [enums.RoleCode.SUPER_ADMIN]: "Super Admin",
    [enums.RoleCode.OPS_MANAGER]: "Operations Manager",
  },
  anErrorHasOccurred: "An error has occurred",
};
