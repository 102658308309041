import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LoginPage from "views/LoginPage/LoginPage";
import Profile from "views/Profile/Profile";
import AccessManagement from "./entities/AccessManagement";


var routes = [
  {
    hidden: true,
    path: "/login",
    name: "Login Page",
    rtlName: "هعذاتسجيل الدخول",
    rtlMini: "هعذا",
    component: LoginPage,
    layout: "/auth",
  },
  {
    path: `/profile`,
    name: "Profile",
    sidebarName: "Profile",
    icon: AccountCircleIcon,
    component: Profile,
    action: "read",
    layout: "/admin",
  },
  { ...AccessManagement },
];

export default routes;
