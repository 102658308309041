import { getI18n } from "react-i18next";
import _ from "lodash";
import * as yup from "yup";
import { toast } from "react-toastify";
import i18n from "i18n";
import enums from "enums/index";
import translations from "constants/translations/index";

export const getDateString = (
  d,
  locale = "en-GB",
  includeTime = false,
  options
) => {
  var date = new Date(d);

  const dateOptions = options
    ? options
    : {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };
  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
  };

  return (
    (includeTime
      ? date.toLocaleTimeString(
          locale === "en-EG" ? "en-US" : locale,
          timeOptions
        ) + ", "
      : "") +
    date.toLocaleDateString(locale === "en-EG" ? "en-GB" : locale, dateOptions)
  );
};

export const flatten = (arr) => {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(
      Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
    );
  }, []);
};

export const setLocaleCookie = (lang) => {
  document.cookie = `i18next=${lang}; expires=Fri, 31 Dec 2099 23:59:59 GMT; path=/`;
};

// export const createValidationSchema = (formStructure) => {
//   let validationObj = {};
//   if (formStructure) {
//     for (let i = 0; i < formStructure.length; i++) {
//       for (let j = 0; j < formStructure[i].fields.length; j++) {
//         validationObj[formStructure[i].fields[j].name] =
//           formStructure[i].fields[j].validation;
//       }
//     }
//   }
//   return yup.object(validationObj);
// };

export const createValidationSchemas = (formStructure) => {
  let validationObj = {};
  if (formStructure) {
    for (let i = 0; i < formStructure.length; i++) {
      for (let j = 0; j < formStructure[i].fields.length; j++) {
        const fieldName = formStructure[i].fields[j].name;
        const fieldValidation = formStructure[i].fields[j].validation;
        const nestedFields = fieldName.split("."); // Split the field name by dot to get the nested structure
        // Create the nested structure dynamically
        let yupObj = nestedFields.reverse().reduce((acc, field, index) => {
          //check if last item
          if (index === nestedFields.length - 1) return acc;
          return yup.object().shape({
            [field]: acc,
          });
        }, fieldValidation);
        if (nestedFields.length > 1) {
          validationObj[nestedFields[nestedFields.length - 1]] = yupObj;
        } else {
          validationObj[fieldName] = yupObj;
        }
      }
    }
  }
  return yup.object(validationObj);
};

export const createValidationSchema = (formStructure) => {
  let fieldPaths = {};
  let validationObj = {};
  if (formStructure) {
    for (let i = 0; i < formStructure.length; i++) {
      for (let j = 0; j < formStructure[i].fields.length; j++) {
        fieldPaths[formStructure[i].fields[j].name] =
          formStructure[i].fields[j].validation;
      }
    }
    validationObj = deepen(fieldPaths);
    validationObj = transformToYup(validationObj);
  }
  return validationObj;
};

// export const createValidationSchema = (formStructure) => {
//   let validationObj = {};
//   if (formStructure) {
//     for (let i = 0; i < formStructure.length; i++) {
//       for (let j = 0; j < formStructure[i].fields.length; j++) {
//         const fieldName = formStructure[i].fields[j].name;
//         const fieldValidation = formStructure[i].fields[j].validation;
//         const nestedFields = fieldName.split("."); // Split the field name by dot to get the nested structure
//         // Create the nested structure dynamically
//         let yupObj = nestedFields.reverse().reduce((acc, field, index) => {
//           //check if last item
//           if (index === nestedFields.length - 1) return acc;
//           return yup.object().shape({
//             [field]: acc,
//           });
//         }, fieldValidation);
//         if (nestedFields.length > 1) {
//           if (validationObj[nestedFields[nestedFields.length - 1]]) {
//             // Merge existing object with new one
//             validationObj[nestedFields[nestedFields.length - 1]] = {
//               ...validationObj[nestedFields[nestedFields.length - 1]],
//               ...yupObj,
//             };
//           } else {
//             validationObj[nestedFields[nestedFields.length - 1]] = yupObj;
//           }
//         } else {
//           validationObj[fieldName] = yupObj;
//         }
//       }
//     }
//   }
//   return yup.object(validationObj);
// };

export const setObjectProp = (obj, path, value) => {
  const pList = path.split(".");
  const key = pList.pop();
  const pointer = pList.reduce((accumulator, currentValue) => {
    if (accumulator[currentValue] === undefined) accumulator[currentValue] = {};
    return accumulator[currentValue];
  }, obj);
  pointer[key] = value;
  return obj;
};

export const setPageSettingsProp = (propPath, value) => {
  let settings = JSON.parse(localStorage.getItem("settings"));
  if (settings === null) {
    settings = {};
  }
  setObjectProp(settings, propPath, value);
  localStorage.setItem("settings", JSON.stringify(settings));
};

export const replaceUrlSearchParam = (
  currentSearch,
  param,
  value,
  force = true
) => {
  let search = new URLSearchParams(currentSearch);
  if (force || search.has(param)) {
    search.set(param, value);
  }

  return search?.toString();
};

export const getDateTimeString = (isoString) => {
  const date = new Date(isoString);
  const splitDate = date
    .toLocaleString("en", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    })
    .split(", ");

  return (
    splitDate[0].split(".").reverse().join("-") +
    "T" +
    splitDate[1].substr(0, 5)
  );
};

export const getSizeString = (bytes, si = true, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
};

export const detectBrowser = () => {
  try {
    // Opera 8.0+
    const isOpera =
      (!!window.opr && !!window.opr.addons) ||
      !!window.opera ||
      navigator.userAgent.indexOf(" OPR/") >= 0;
    if (isOpera) return "Opera";

    // Firefox 1.0+
    const isFirefox = typeof InstallTrigger !== "undefined";
    if (isFirefox) return "Firefox";

    // Safari 3.0+ "[object HTMLElementConstructor]"
    const isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof window["safari"] !== "undefined" &&
            window["safari"]?.pushNotification)
      );
    if (isSafari) return "Safari";

    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;
    if (isIE) return "IE";

    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;
    if (isEdge) return "Edge";
  } catch {
    return undefined;
  }
};

export const accessObjectByString = (obj, string) => {
  const keys = string.split(".");
  let result = obj;
  let found = true;
  keys.forEach((key) => {
    if (!result[key]) {
      found = false;
      return;
    }
    result = result[key];
  });
  return found ? result : undefined;
};

export const isEmpty = (val) =>
  typeof val === "boolean" || typeof val === "number"
    ? false
    : val === undefined ||
      val === null ||
      val === "" ||
      (Array.isArray(val) && val.length === 0) ||
      _.isEmpty(val);

export const contains = (string, substring) => {
  return string
    ? string.toLowerCase().indexOf(substring.toLowerCase()) !== -1
    : false;
};

export const checkIfHexadecimal = (str) => {
  const regexp = /^[0-9a-fA-F]+$/;
  if (regexp.test(str)) {
    return true;
  } else {
    return false;
  }
};

// Function that takes date string and returns month and year in format 'Month Year (e.g. June 2022)'
export const getMonthYear = (dateString) => {
  const date = new Date(dateString);
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();
  return dateString ? `${month} ${year}` : "-";
};

// Function that takes birth date and returns age in format 'Age (e.g. 22)'
export const getAge = (date) => {
  const today = new Date();
  const birthDate = new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const deepen = (obj, splitBy = ".") => {
  const result = {};

  // For each object path (property key) in the object
  for (const objectPath in obj) {
    // Split path into component parts
    const parts = objectPath.split(splitBy);

    // Create sub-objects along path as needed
    let target = result;
    while (parts.length > 1) {
      const part = parts.shift();
      target = target[part] = target[part] || {};
    }

    // Set value at end of path
    target[parts[0]] = obj[objectPath];
  }

  return result;
};

// Helper function to recursively transform the nested object into nested Yup object
function transformToYup(obj) {
  return yup.object().shape(
    Object.keys(obj).reduce((res, key) => {
      if (obj[key] === undefined || obj[key] === null) {
        // If the value is undefined or null, assign yup.mixed()
        res[key] = yup.mixed();
      } else if (
        !(obj[key] instanceof yup.lazy) &&
        !(obj[key] instanceof yup.mixed)
      ) {
        // If it's not already a Yup schema, continue to transform
        res[key] = transformToYup(obj[key]);
      } else {
        // If it is a Yup schema, just assign it
        res[key] = obj[key];
      }
      return res;
    }, {})
  );
}

export const deepenWithArrays = (obj, splitBy = ".") => {
  const result = {};

  for (const objectPath in obj) {
    const parts = objectPath.split(splitBy);
    let currentTarget = result;
    let lastKey = null;

    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];
      const isArray = part.match(/^(.+)\[(\d+)\]$/);

      if (isArray) {
        const key = isArray[1];
        const index = parseInt(isArray[2]);

        if (!currentTarget[key]) {
          currentTarget[key] = [];
        }

        while (currentTarget[key].length < index) {
          currentTarget[key].push({});
        }

        if (i === parts.length - 1) {
          currentTarget[key][index] = obj[objectPath];
        } else {
          if (!currentTarget[key][index]) {
            currentTarget[key][index] = {};
          }
          currentTarget = currentTarget[key][index];
        }
        lastKey = index;
      } else {
        if (i === parts.length - 1) {
          currentTarget[part] = obj[objectPath];
        } else {
          if (!currentTarget[part]) {
            currentTarget[part] = {};
          }
          currentTarget = currentTarget[part];
        }
        lastKey = part;
      }
    }

    if (lastKey && Array.isArray(currentTarget[lastKey])) {
      currentTarget[lastKey] = currentTarget[lastKey].filter(Boolean);
    }
  }

  populateEmptyObjects(result);

  return result;
};

function populateEmptyObjects(obj) {
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      for (let i = 0; i < obj[key].length; i++) {
        if (typeof obj[key][i] === "undefined") {
          obj[key][i] = {};
        }
        populateEmptyObjects(obj[key][i]);
      }
    } else if (typeof obj[key] === "object") {
      populateEmptyObjects(obj[key]);
    }
  }
}

export const flattenObject = (data, keepArrays = true) => {
  var result = {};
  function recurse(cur, prop) {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur) && !keepArrays) {
      for (var i = 0, l = cur.length; i < l; i++)
        recurse(cur[i], prop + "[" + i + "]");
      if (l == 0) result[prop] = [];
    } else if (Array.isArray(cur) && keepArrays) {
      result[prop] = cur;
    } else {
      var isEmpty = true;
      for (var p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? prop + "." + p : p);
      }
      if (isEmpty && prop) result[prop] = {};
    }
  }
  recurse(data, "");
  return result;
};

export const injectFilterOperators = (filters, inputs) => {
  const newFilters = flattenObject(filters);

  inputs.forEach((input) => {
    if (
      newFilters[input.name] !== "" &&
      newFilters[input.name] !== undefined &&
      newFilters[input.name] !== null
    ) {
      let value;
      switch (true) {
        case input.type === "text":
          value = newFilters[input.name];
          delete newFilters[input.name];
          newFilters[`${input.name}.regex`] = value;
          break;
        case input.operation === "range" &&
          input.name.split(".").reverse()[0] === "from":
          const name = input.name.split(".").slice(0, -1).join(".");
          value = [newFilters[input.name], newFilters[name + ".to"]];
          delete newFilters[input.name];
          delete newFilters[name + ".to"];
          newFilters[`${name}.range`] = value;
          break;
        case input.operation === "range" &&
          input.name.split(".").reverse()[0] === "to":
          const name1 = input.name.split(".").slice(0, -1).join(".");
          const fromValue = newFilters[name1 + ".from"] || null;
          value = [fromValue, newFilters[input.name]];
          delete newFilters[input.name];
          delete newFilters[name1 + ".from"];
          newFilters[`${name1}.range`] = value;
          break;
        case input.type === "autocomplete" &&
          newFilters[input.name]?.length > 0 &&
          typeof newFilters[input.name][0] === "object":
          if (input.operation === "multi-range")
            value = newFilters[input.name].map((entry) => ({
              range: entry.id,
            }));
          else value = newFilters[input.name].map((entry) => entry.id);
          delete newFilters[input.name];
          newFilters[input.name] = value;
          break;
        case input.operation === "multi-range":
          value = newFilters[input.name].map((entry) => ({ range: entry }));
          delete newFilters[input.name];
          newFilters[input.name] = value;
          break;
      }
    } else {
      delete newFilters[input.name];
    }
  });
  return deepen(newFilters);
};

export const getYears = (numberOfYears = 2) => {
  const years = [];
  for (let i = 2019; i <= new Date().getFullYear() + numberOfYears; i++) {
    years.push(i);
  }
  return years;
};

export const isEmptyObject = (obj) => {
  return Object.keys(obj).every(
    (key) =>
      obj[key] === "" ||
      obj[key] === null ||
      obj[key] === undefined ||
      obj[key] == [] ||
      obj[key] == {}
  );
};

export const isSubset = (subset, set) => {
  if (Array.isArray(subset) && Array.isArray(set)) {
    if (subset.length === 0) {
      return true;
    }
    if (set.length === 0) {
      return false;
    }
    if (typeof set[0] === "object") {
      if (typeof subset[0] === "object") {
        return subset.every((subsetEntry) =>
          set.some(
            (setEntry) =>
              Object.keys(subsetEntry).every(
                (key) => subsetEntry[key] === setEntry[key]
              ) && Object.keys(setEntry).every((key) => key in subsetEntry)
          )
        );
      } else {
        return false;
      }
    } else {
      return subset.every((subsetEntry) => set.includes(subsetEntry));
    }
  } else {
    return false;
  }
};

export const isEqual = (array1, array2) => {
  if (!array1 || !array2 || array1.length !== array2.length) {
    return false;
  }
  const array1Ids = array1.map((item) => item.id);
  const array2Ids = array2.map((item) => item.id);
  return array1Ids.every((id) => array2Ids.includes(id));
};

export const getEnumValues = (translationObjectName) => {
  const lang = getI18n().language;
  return Object.keys(translations[lang][translationObjectName]).map((key) => ({
    value: key,
    name: translations[lang][translationObjectName][key],
  }));
};

export const capitalizeFirstLetter = (string) => {
  if (typeof string !== "string") return "";
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const formatEnums = (string) => {
  let outputString = string.replace(/_/g, " ").toLowerCase();
  outputString = outputString.replace(/\b\w/g, (c) => c.toUpperCase());
  return outputString;
};

export const toastError = (errorMessage) => {
  if (typeof errorMessage === "object" && errorMessage.length) {
    errorMessage = errorMessage.forEach((err, index) => {
      toast.error(`${index + 1}. ${capitalizeFirstLetter(err)}`);
    });
  } else {
    toast.error(capitalizeFirstLetter(errorMessage));
  }
};

export const getMaxDate = (dates) => {
  if (dates?.length) {
    const tempDates = dates.map((date) => date.replace(/-/g, ""));
    let latestDate = Math.max(...tempDates).toString();
    latestDate = latestDate.replace(/(\d{4})(\d{2})(\d{2})/g, "$1-$2-$3");
    return latestDate;
  }
};

export const round = (number, precision = 1) => {
  const factor = Math.pow(10, precision);
  return Math.round(number / factor) * factor;
};

export const toNearest = (number, nearest = 2) => {
  return isFinite(number) ? parseFloat(number.toFixed(nearest)) : "N/A";
};

export const getDurationString = (durationInMinutes) => {
  const hours = Math.floor(durationInMinutes / 60);
  const remainingMinutes = durationInMinutes % 60;
  return `${hours ? hours + " hr" : ""} ${
    remainingMinutes ? remainingMinutes + " min" : ""
  }`;
};

export const generateId = () => {
  return Math.random().toString(36).substring(2, 9);
};

export const convertToBracketNotation = (str) => {
  return str.replace(/\.(\d+)\./g, "[$1].").replace(/\.(\d+)$/g, "[$1]");
};

export const getFutureDate = (numberOfDays) => {
  const date = new Date();
  date.setDate(date.getDate() + numberOfDays);
  return date.toISOString().split("T")[0];
};

export const convertTimeToISO = (date, time) => {
  return new Date(`${date}T${time}`).toISOString();
};

export const extractTimeFromISO = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleTimeString("en-GB").substring(0, 5);
};

export const formatNames = (nameEn, nameAr) => {
  return nameEn && nameAr
    ? `${nameEn} - ${nameAr}`
    : nameEn
    ? nameEn
    : nameAr
    ? nameAr
    : "N/A";
};

export const applyTimezoneOffset = (isoString) => {
  if (!isoString) return null;
  const date = new Date(isoString);
  const dateString = date.toLocaleDateString("en-GB");
  const timeString = date.toLocaleTimeString("en-GB");
  const day = dateString.split("/")[0];
  const month = dateString.split("/")[1];
  const year = dateString.split("/")[2];
  const hours = timeString.split(":")[0];
  const minutes = timeString.split(":")[1];
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const displayCustomerName = (customer) => {
  let result = "";
  if (customer?.name) {
    result += customer.name;
  }
  if (customer?.primaryPhoneNumber) {
    result += " - " + customer.primaryPhoneNumber;
  }
  if (customer?.secondaryPhoneNumber) {
    result += " - " + customer.secondaryPhoneNumber;
  }
  if (customer?.email) {
    result += " - " + customer.email;
  }
  return result;
};

export const displayProductName = (product) => {
  let result = "";
  if (product?.sapCode) {
    result += product.sapCode;
  }
  if (product?.batterySize) {
    result += " - " + product.batterySize.size;
  }
  if (product?.manufacturer) {
    result += " - " + product.manufacturer.name;
  }
  if (product?.group) {
    result += " - " + translations["en"].productCategory[product.group];
  }
  if (product?.unitPrice) {
    result += " - " + product.unitPrice;
  }
  return result;
};

export const displayCarName = (carModel) => {
  let result = "";
  if (carModel?.maker) {
    result += carModel.maker.name;
  }
  if (carModel?.name) {
    result += " - " + carModel.name;
  }
  if (carModel?.year) {
    result += " - " + carModel.year;
  } else {
    result += " - Unknown Year";
  }
  return result;
};
export const isOrderEditable = (order, currentUser) => {
  return (
    order?.status !== enums.OrderStatus.CANCELLED &&
    order?.status !== enums.OrderStatus.COMPLETED &&
    order?.status !== enums.OrderStatus.LOST &&
    order?.status != enums.OrderStatus.PENDING_STOCK_APPROVAL &&
    order?.status !== enums.OrderStatus.STOCK_REJECTED
  );
};
export const isOrderCompleted = (order) => {
  return order?.status === enums.OrderStatus.COMPLETED;
};
export const displayTransferParty = (transferParty) => {
  if (!transferParty) return "";
  if (transferParty?.type === enums.TransferPartyType.CUSTOMER) {
    return (
      transferParty.customer.name +
      " - " +
      transferParty.customer.primaryPhoneNumber
    );
  } else {
    if (transferParty.storage) {
      return displayStorageName(transferParty.storage);
    }
  }

  return "";
};
export const displayStorageName = (storage) => {
  if (!storage) return "";
  if (storage?.type === enums.StorageType.TRUCK) {
    return storage.truck.truckNumber;
  } else {
    return storage.warehouse.name;
  }
};
export const displayFileName = (url) => {
  const urlSegments = url.split("/");
  const lastSegment = urlSegments[urlSegments.length - 1];

  const fileName = lastSegment.split("_")[0];
  const fileNameParts = lastSegment.split(".");
  const fileExtension = fileNameParts[fileNameParts.length - 1];

  const fullFilename = `${fileName}.${fileExtension}`;
  return fullFilename;
};
