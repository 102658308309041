export default {
  FormContainerType: { PAGE: "PAGE", MODAL: "MODAL" },
  Action: {
    CREATE: "create",
    READ: "read",
    UPDATE: "update",
    DELETE: "delete",
  },
  Resource: { ACCESS_MANAGEMENT: "ACCESS_MANAGEMENT" },
  RoleCode: {
    SUPER_ADMIN: "SUPER_ADMIN",
    OPS_MANAGER: "OPS_MANAGER",
  },
  InfoType: {
    TEXT: "TEXT",
    DATE: "DATE",
    BOOLEAN: "BOOLEAN",
    LIST: "LIST",
    LINK: "LINK",
    EXTERNAL_LINK: "EXTERNAL_LINK",
    FILE: "FILE",
    TAGS: "TAGS",
  },
};
